footer.site-footer {
	.footer-top {
	    background-color: $color02;
	    padding: 55px 0 30px;
	    @include media-query($desktop-min) {
	    	padding: 40px 0 25px;
	    }
		.footer-logo-content {
			text-align: left;
		    display: flex;
		    align-items: center;
	        @include media-query($mobile-max) {
				margin: 0 0 40px;
	        }
			img {
				/*margin: 0 0 20px;*/
				max-width: 280px;
    			margin: 0 20px 20px 0;
    			height: 60px;
		        @include media-query($mobile-max) {
					max-width: 240px;
		        }
		    }
		    p {
				font-size: 18px;
				color: $white;
		        @include media-query($mobile-max) {
					font-size: 16px;
		        }
		    }
		}
		.footer-menu {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
    		align-content: center;
			align-items: center;
    		height: 100%;
	        @include media-query($tablet-min) {
	        	margin: 0px 0 12px;
				justify-content: center;
	        	width: 100%;
	        }
    		li {
    			list-style: none;
    			margin: 0 8px;
    			a {
    				font-size: 16px;
    				color: $white;
    				text-decoration: none;
    				text-transform: uppercase;
    				&:hover {
	    				text-decoration: underline;
    				}
    				&.active {
	    				text-decoration: underline;
    					color: $white;
    				}
    			}
    			&:first-child {
    				margin-left: 0;
    			}
				&.active {
					a {
						/*color: $black;*/
	    				text-decoration: underline;
    					color: $white;
					} 
				}
    		}
		}
	}
	.footer-bottom {
		padding: 15px 0 5px;
		background-color: $color01;
		p {
			text-align: center;
			font-size: 18px;
			color: $white;
		    line-height: 1.4;
		    margin: 0 0 12px;
	        @include media-query($mobile-max) {
				font-size: 16px;
	        }
	        a {
	        	color: $white;
	        	text-decoration: underline;
	        	&:hover {
	        		color: $color02;
	        		text-decoration: none;
		        	transition: 0.3s all;
	        	}
	        }
		}
	}
}

body.blogs-body footer.site-footer .footer-top .footer-menu li:nth-child(6) a {
	color: $white;
	text-decoration: underline;
}
body.Contact.Us footer.site-footer .footer-top .footer-menu li:nth-child(5) a {
	color: $white;
	text-decoration: underline;
}
body.single-blog-page-body footer.site-footer .footer-top .footer-menu li:nth-child(6) a  {
	color: $white;
	text-decoration: underline;
}
body.Research footer.site-footer .footer-top .footer-menu li:nth-child(7) a {
	color: $white;
	text-decoration: underline;
}