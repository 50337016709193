.hidden-homeS {
    height: 150px;
    background-color: $black;
    visibility: hidden;
    @include media-query($tablet-max) {
        height: 120px;
    }
    @include media-query($tablet-min) {
        height: 130px;
    }
}
.HomeSlideShow {
    margin-top: 0;
    position: relative;
    z-index: 1;
    height: 100%;
    @include media-query($tablet-max) {
        margin-top: 0;
    }
    @include media-query($tablet-min) {
        margin-top: 0;
    }
    .HomeSlider {
        margin: 0px !important;
        .item {
            height: 100%;
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                bottom: 0;
                z-index: 2;
                background-color: rgba($black,0.3);
            }
            img {
                width: 100%;
                height: 660px;
                object-fit: cover;
                transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
                @include media-query($desktop-min) {
                    height: 600px;
                }
                @include media-query($tablet-pot-max) {
                    height: 540px;
                }
                @include media-query($tablet-pot-min) {
                    height: 480px;
                }
                @include media-query($mobile-max) {
                    height: 400px;
                }
            }
            .HomeSlider-content {
                position: absolute;
                margin: 0 auto;
                padding: 0 15px;
                max-width: 1200px;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 5;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                word-break: break-word;
                text-align: center;
                @include media-query($tablet-max) {
                    padding: 0 85px;
                }
                @include media-query($mobile-max) {
                    padding: 0 15px;
                }
                .HomeSlider-content-inner{
                    text-align: center;
                    width: 100%;
                     h1 {
                        font-size: 70px;
                        line-height: 1.2;
                        color: $white;
                        font-weight: 700;
                        font-family: $fontStyle1;
                        text-align: center;
                        margin: 0 auto;
                        padding: 80px 0 0;
                        max-width: 800px;
                        @include media-query($desktop-min) {
                            font-size: 58px;
                        }
                        @include media-query($tablet-max) {
                            font-size: 48px;
                        }
                        @include media-query($tablet-min) {
                            font-size: 42px;
                        }
                        @include media-query($mobile-max) {
                            font-size: 36px;
                        }
                    }
                    a {
                        position: relative;
                        overflow: hidden;
                        display: inline-block;
                        margin: 0;
                        padding: 16px 32px;
                        transition: 0.5s all;
                        color: $white;
                        background-color: $color01;

                        font-size: 12px;
                        font-weight: normal;
                        line-height: 1;
                        letter-spacing: 0.15em;
                        text-decoration: none;
                        text-transform: uppercase;
                        @include media-query($tablet-min) {
                            font-size: 14px;
                        }
                        @include media-query($mobile-max) {
                            padding: 12px 20px;
                            font-size: 12px;
                        }
                        span {
                            position: relative;
                            z-index: 1;
                        }
                        &:focus {
                            outline: none;
                        }
                        &:hover {
                            color: $white;
                            transition: 0.5s all;
                            background-color: $color02;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        button.slick-prev {
            left: 15px;
            width: 60px;
            height: 60px;
            background-color: rgba($color02,0.4);
            z-index: 8;
            &:before {
                content: "\f104" !important;
                font-family: FontAwesome;
                right: 2px;
                font-size: 40px;
                position: relative;
            }
            @include media-query($mobile-max) {
                display: none !important;
            }
        }
        button.slick-next {
            right: 15px;
            width: 60px;
            height: 60px;
            background-color: rgba($color02,0.4);
            &:before {
                content: "\f105" !important;
                font-family: FontAwesome;
                left: 2px;
                font-size: 40px;
                position: relative;
            }
            @include media-query($mobile-max) {
                display: none !important;
            }
        }
        .slick-dots {
            bottom: 25px;
            li {
                height: 6px;
                button {
                    height: 6px;
                    &:before {
                        color: $white;
                        background: $white;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        top: 0px;
                        font-size: 0;
                    }
                }
            }
        }
        .slick-active img {
            height: 660px;
            object-fit: cover;
            @include media-query($desktop-min) {
                height: 600px;
            }
            @include media-query($tablet-pot-max) {
                height: 540px;
            }
            @include media-query($tablet-pot-min) {
                height: 480px;
            }
            @include media-query($mobile-max) {
                height: 400px;
            }
        }
        &.slick-slider {
            margin: 0 0 5px;
            .slick-list{
                height: 660px;
                @include media-query($desktop-min) {
                    height: 600px;
                }
                @include media-query($tablet-pot-max) {
                    height: 540px;
                }
                @include media-query($tablet-pot-min) {
                    height: 480px;
                }
                @include media-query($mobile-max) {
                    height: 400px;
                }
            }
        }
        .slider-track {
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        }
    }
}
.specialties-part {
    padding: 50px 0;
    background-size: cover;
    background-repeat: no-repeat;
    .container {
        h2 {
            text-align: center;
            font-size: 60px;
            line-height: 1.4;
            color: $color01;
            font-weight: 600;
            font-family: $fontStyle1;
            margin: 0 0 50px;
            @include media-query($tablet-max) {
                font-size: 50px;
            }
            @include media-query($tablet-min) {
                font-size: 42px;
            }
            @include media-query($mobile-max) {
                margin: 0 0 35px;
                font-size: 32px;
            }
        }
        .specialties-col {
            margin: 0 0 50px;
            padding: 0 20px;
            @include media-query($mobile-max) {
                margin: 0 0 20px;
                padding: 0 15px;
            }
            .specialties-block {
                position: relative;
                height: 100%;
                background: $white;
                padding: 40px 40px 20px;
                text-align: center;
                box-shadow: 0 0 22px rgba($color04, 0.35);
                transition: 0.3s all;
                @include media-query($mobile-max) {
                    padding: 25px 15px 15px;
                }
                .specialties-img-block {
                    img {
                        margin: 0 0 28px;
                        height: 90px;
                        width: 160px;
                        object-fit: contain;
                    }
                }
                .contents {
                    h4 {
                        text-transform: uppercase;
                        font-size: 18px;
                        margin: 0 0 15px;
                        a {
                            color: $color02;
                            text-decoration: none;
                        }
                    }
                    p {
                        font-size: 16px;
                        line-height: 20px;
                        color: $textColor1;
                    }
                }
                &:hover {
                    transition: 0.3s all;
                    box-shadow: 0 0 30px rgba($color01, 0.35);
                    &:before {
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 4px;
                        background-color: $color01;
                        position: absolute;
                    }
                }
            }
        }
    }
}

.home-about-part {
    padding: 50px 0 70px;
    .container {
        h2 {
            text-align: center;
            font-size: 60px;
            line-height: 1.4;
            color: $color01;
            font-weight: 600;
            font-family: $fontStyle1;
            margin: 0 0 50px;
            @include media-query($tablet-max) {
                font-size: 50px;
            }
            @include media-query($tablet-min) {
                font-size: 42px;
            }
            @include media-query($mobile-max) {
                margin: 0 0 35px;
                font-size: 32px;
            }
        }
        .about-dr-img {
            position: relative;
            max-width: 500px;
            @include media-query($tablet-min) {
                margin: 0 0 30px;
            }
            img {
                max-width: 500px;
                border-radius: 0 120px 0 0;
                width: 100%;
                @include media-query($mobile-max) {
                    max-width: 90%;
                }
                &.about-line-bg {
                    position: absolute;
                    bottom: 0;
                    left: 30px;
                    right: 0;
                    top: 30px;
                    z-index: -1;
                    pointer-events: none;
                    border-radius: 0px;
                }
            }
            .about-dr-box {
                /*max-width: 530px;*/
                /*max-width: 474px;*/
                width: 100%;
                max-width: 100%;
                padding: 18px 30px 5px;
                /*position: relative;*/
                position: absolute;
                bottom: 0;
                background-color: rgba($color02, 0.7);
                margin: 0px;
                /*margin: -145px 68px 0 auto;*/
                /*margin: -175px 0 0 0;*/
                @include media-query($tablet-min) {
                    /*margin: -90px 68px 30px auto;*/
                    /*margin: -154px 0 30px 0px;*/
                }
                @include media-query($mobile-max) {
                    max-width: 90%;
                }
                h3 {
                    font-size: 40px;
                    color: $white;
                    font-family: $fontStyle2B;
                    margin: 0 0 10px;
                    @include media-query($tablet-pot-max) {
                        font-size: 32px;
                    }
                    @include media-query($tablet-min) {
                        font-size: 28px;
                    }
                }
                p {
                    font-size: 18px;
                    color: $white;
                    @include media-query($tablet-min) {
                        font-size: 16px;
                    }
                }
            }
        }
        .about-right-content {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            align-items: center;
            align-content: center;
            padding: 0 35px 0 60px;
            @include media-query($tablet-pot-max) {
                padding: 0 0 0 25px;
            }
            @include media-query($tablet-min) {
                padding: 0px;
            }
            h3 {
                width: 100%;
                font-size: 28px;
                color: $color02;
                font-family: $fontStyle2B;
                margin: 0 0 20px;
                @include media-query($tablet-min) {
                    margin: 0 0 16px;
                    font-size: 24px;
                }
            }
            p {
                width: 100%;
                color: $textColor1;
                font-size: 18px;
                margin: 0 0 15px;
                @include media-query($tablet-min) {
                    font-size: 16px;
                }
                b {
                    font-family: $fontStyle2B;
                }
            }
            a.button-links {
                padding: 14px 14px 10px;
                line-height: 1;
                font-size: 18px;
                color: $white;
                border-radius: 8px;
                text-transform: uppercase;
                background-color: $color01;
                margin: 20px 0 30px;
                text-decoration: none;
                &:hover {
                    background-color: $color02;
                    transition: 0.3s all;
                }
            }
            .about-contact-links {
                width: 100%;
                a {
                    font-size: 18px;
                    color: $textColor1;
                    word-break: break-word;
                    margin: 0 0 10px;
                    display: inline-flex;
                    align-items: center;
                    img {
                        width: 16px;
                        height: 16px;
                        object-fit: contain;
                        margin: 0 15px 0 0;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $color01;
                    }
                }   
            }
        }
    }
}

.home-appointment-part {
    padding: 50px 0 70px;
    background-size: cover;
    background-position: right;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.6);
    }
    .container {
        position: relative;
        h2 {
            text-align: center;
            font-size: 60px;
            line-height: 1.4;
            color: $white;
            font-weight: 600;
            font-family: $fontStyle1;
            margin: 0 0 50px;
            @include media-query($tablet-max) {
                font-size: 50px;
            }
            @include media-query($tablet-min) {
                font-size: 42px;
            }
            @include media-query($mobile-max) {
                margin: 0 0 35px;
                font-size: 32px;
            }
        }
        .left-part {
            padding-top: 50px;
            @include media-query($tablet-min) {
                padding-top: 25px;
                margin: 0 0 35px;
            }
            @include media-query($mobile-max) {
                padding-top: 0;
                margin: 0 0 20px;
            }
            p {
                margin: 0 0 15px;
                font-size: 18px;
                color: $white;
            }
            form {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;
                max-width: 600px;
                @include media-query($tablet-min) {
                    max-width: 100%;
                    margin: 0px;
                }
                .form-group {
                    width: 50%;
                    padding: 0 10px 4px;
                    @include media-query($tablet-min) {
                        padding: 0 5px 0px;
                    }
                    @include media-query($mobile-min) {
                        width: 100%;
                        padding: 0px;
                    }
                    input {
                        height: 50px;
                        font-size: 18px;
                        color: $black;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        border-radius: 10px;
                    }
                    textarea {
                        padding-top: 10px;
                        height: 105px;
                        font-size: 18px;
                        color: $black;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        border-radius: 10px;
                        resize: none;
                    }
                    .send-button {
                        width: 100%;
                        color: $white;
                        background-color: $color01;
                        font-family: $fontStyle2B;
                        padding-top: 5px;
                        &:hover {
                            background-color: $color02;
                            transition: 0.3s all;
                        }
                    }
                    &.form-group-fullW {
                        width: 100%;
                    }
                    .alert-error {
                        margin: 15px 0 0;
                    }
                    .alert-success {
                        margin: 15px 0 0;
                    }
                }
            }
        }
        .right-part {
            padding-top: 35px;
            @include media-query($tablet-min) {
                padding-top: 25px;
            }
            @include media-query($mobile-max) {
                padding-top: 0px;
            }
            h3 {
                font-size: 28px;
                color: $white;
                font-family: $fontStyle2B;
                font-weight: bold;
                max-width: 580px;
                margin: 0 0 15px auto;
                @include media-query($tablet-min) {
                    max-width: 100%;
                    margin: 0 0 15px;
                }
                @include media-query($mobile-max) {
                    font-size: 24px;
                }
            }
            .consultant-boxes {
                border: 1px solid $white;
                max-width: 580px;
                margin: 0 0 0 auto;
                @include media-query($tablet-min) {
                    max-width: 100%;
                    margin: 0;
                }
                &:last-child {
                    border-top: none;
                    border-bottom: none;
                }
                h4 {
                    font-size: 20px;
                    color: $color02;
                    margin: 0;
                    padding: 15px 20px;
                    background-color: $white;
                    font-family: $fontStyle2B;
                    font-weight: bold;
                    @include media-query($mobile-min) {
                        font-size: 18px;
                    }
                }
                .timings-cons {
                    padding: 14px 20px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .left-area {
                        width: 50%;
                        font-size: 18px;
                        color: $white;
                        @include media-query($mobile-min) {
                            width: 100%;
                            font-size: 16px;
                            text-align: left;
                        }
                        i {
                            margin: 0 10px 0 0;
                        }
                    }
                    .right-area {
                        width: 50%;
                        text-align: right;
                        @include media-query($mobile-min) {
                            width: 100%;
                            text-align: left;
                            margin: 5px 0 0;
                        }
                        p {
                            font-size: 18px;
                            color: $white;
                            margin: 0 0 4px;
                            @include media-query($mobile-min) {
                                font-size: 16px;
                                margin: 0 0 2px;
                            }
                            b {
                                font-family: $fontStyle2B;
                            }
                        }
                    }
                }
                .appointment-call {
                    text-align: center;
                    padding: 10px 20px;
                    color: $white;
                    border-top: 1px solid $white;
                    border-bottom: 1px solid $white;
                    margin: 0 0 20px;
                    @include media-query($mobile-min) {
                        padding: 8px 16px;
                    }
                    p {
                        margin: 0px;
                        font-size: 18px;
                        line-height: 1.4;
                        @include media-query($mobile-min) {
                            font-size: 16px;
                        }
                        a {
                            font-family: $fontStyle2B;
                            color: $white;
                            @include media-query($mobile-min) {
                                display: inline-block;
                            }
                            i {
                                margin: 0 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.home-affiliations-part {
    padding: 80px 0;
    background-color: $backgroundColor2;
    @include media-query($tablet-min) {
        padding: 60px 0;
    }
    h2 {
        text-align: center;
        font-size: 60px;
        line-height: 1.4;
        color: $color01;
        font-weight: 600;
        font-family: $fontStyle1;
        margin: 0 0 50px;
        @include media-query($tablet-max) {
            font-size: 50px;
        }
        @include media-query($tablet-min) {
            font-size: 42px;
        }
        @include media-query($mobile-max) {
            margin: 0 0 35px;
            font-size: 32px;
        }
    }
    .right-part {
        margin: 0 0 40px;
        @include media-query($tablet-min) {
            margin: 0 0 20px;
        }
        // &:last-child {
        //     margin: 0px;
        // }
        .inner-area {
            // margin: 0 0 20px;
            height: 100%;
            @include media-query($tablet-min) {
                max-width: 100%;
            }
            .address-box {
                padding: 40px 0 80px;
                background: $white;
                height: 100%;
                position: relative;
                h6 {
                    font-size: 20px;
                    color: $color02;
                    font-family: $fontStyle2B;
                    padding: 0 30px;
                }
                p {
                    padding: 0 30px;
                    font-size: 18px;
                    line-height: 1.4;
                    color: $textColor1;
                    display: flex;
                    align-items: center;
                    margin: 0 0 20px;
                    a {
                        text-decoration: none;
                        color: $color01;
                        &:hover {
                            color: $color02;
                        }
                    }
                    i {
                        color: #e40026;
                        font-size: 16px;
                        line-height: 25px;
                        min-width: 25px;
                        min-height: 25px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: #efefef;
                        margin: 0 15px 0 0;
                    }
                }
                .clinic-times {
                    flex-wrap: wrap;
                    display: flex;
                    padding: 0 30px;
                    justify-content: space-between;
                    p {
                        padding: 0;
                        font-size: 16px;
                        display: block;
                        margin: 0;
                        span {
                            display: block;
                            position: relative;
                            // padding: 0 20px 5px 0;
                            margin: 0 0 5px;
                            border-bottom: 1px solid rgba(0,0,0,0.10);
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                        // i {
                        //     margin: 0;
                        //     position: absolute;
                        //     top: 2px;
                        //     right: 0;
                        //     line-height: 1;
                        //     background-color: transparent;
                        //     min-width: auto;
                        //     min-height: auto;
                        // }
                    }
                }
                .map-direct-link {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 40px 0 0;
                    padding: 18px 15px 16px;
                    text-align: center;
                    display: block;
                    background-color: $color01;
                    color: $white;
                    font-size: 16px;
                    text-transform: uppercase;
                    line-height: 1;
                    transition: 0.3s all;
                    text-decoration: none;
                    &:hover {
                        background-color: $color02;
                        transition: 0.3s all;
                    }
                }
            }
        }
    }
}

.cookie-modal-open {
    .modal-content {
        background-color: transparent;
        border: none;
        .modal-header {
            background-color: transparent;
            border: none;
            padding: 0;
            button.close {
                padding: 0px;
                color: $white;
                opacity: 1;
                font-weight: 300;
                font-size: 36px;
                transition: 0.3s all;
                margin: 0 0 10px auto;
                &:hover {
                    transition: 0.3s all;
                    opacity: 0.8;
                }
            }
        }
        .modal-body {
            padding: 0px;
            img {
                width: 100%;
            }
        }
    }
}