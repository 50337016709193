*, *::before, *::after {
  box-sizing: border-box;
  text-decoration: none;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/**
 * Basic styling
 */
body {
    font-family: $fontStyle2;
    /*font: $base-font-weight #{$base-font-size}/#{$base-line-height};*/
    color: $text-color;
    margin: 0px;
    padding: 0px;
    &.page-top {
        margin: 150px 0 0;
        @include media-query($tablet-max) {
            margin: 125px 0 0;
        }
        @include media-query($tablet-min) {
            margin: 130px 0 0;
        }
    }
    &.blogs-body {
        header {
            .mainMenu {
                .container {
                    .inner-area {
                        .menu-area {
                            ul.nav-ul {
                                .nav-ul-inner {
                                    li {
                                        &:nth-child(6) {
                                            a {
                                                text-shadow: 1px 1px 0px $color01;
                                                color: $color01;
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        text-shadow: none;
                                                        color: $color02;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.nutrition, &.recipe {
            header {
                .mainMenu {
                    .container {
                        .inner-area {
                            .menu-area {
                                ul.nav-ul {
                                    .nav-ul-inner {
                                        li {
                                            &:nth-child(6) {
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            text-shadow: 1px 1px 0px $color01;
                                                            color: $color01;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.single-blog-page-body {
        header {
            .mainMenu {
                .container {
                    .inner-area {
                        .menu-area {
                            ul.nav-ul {
                                .nav-ul-inner {
                                    li {
                                        &:nth-child(6) {
                                            a {
                                                text-shadow: 1px 1px 0px $color01;
                                                color: $color01;
                                            }
                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        text-shadow: none;
                                                        color: $color02;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.nutrition, &.recipe {
            header {
                .mainMenu {
                    .container {
                        .inner-area {
                            .menu-area {
                                ul.nav-ul {
                                    .nav-ul-inner {
                                        li {
                                            &:nth-child(6) {
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            text-shadow: 1px 1px 0px $color01;
                                                            color: $color01;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.Research.page-top header .mainMenu .container .inner-area .menu-area ul.nav-ul .nav-ul-inner li:nth-child(7) a {
    color: $color01;
    text-shadow: 1px 1px 0px $color01;
}
body.Contact.Us header .mainMenu .container .inner-area .menu-area ul.nav-ul .nav-ul-inner li:nth-child(5) a {
    color: $color01;
    text-shadow: 1px 1px 0px $color01;
}

.container {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 15px;
}

.table-mainDiv {
    @include media-query($tablet-min) {
        overflow: hidden;
        overflow-x: auto;
    }
    table.default-site-table {
        width: 100%;
        margin: 0 0 50px;
        @include media-query($tablet-min) {
            width: 1024px;
        }
        thead {
            th {
                font-size: 20px;
                color: $white;
                padding: 10px 10px 6px;
                background-color: $color01;
                font-weight: 700;
                text-align: center;
                border-left: 1px solid $white;
                &:first-child {
                    border-left: none;
                }
            }
        }
        tbody {
            width: 100%;
            tr {
                td {
                    padding: 10px 10px;
                    font-size: 16px;
                    text-align: center;
                    color: $textColor2;
                    background-color: rgba($textColor1, 0.25);
                    border-left: 1px solid $white;
                    ul {
                        padding: 10px 0 10px 20px;
                        margin: 0px;
                        text-align: left;
                        li {
                            color: $textColor2;
                            font-size: 16px;
                            margin: 0;
                        }
                    }
                    &:first-child {
                        border-left: none;
                    }
                }
                &:nth-child(odd) {
                    td {
                        background-color: rgba($textColor1, 0.1);
                    }
                }
            }   
        }
    }
    &.left-contents {
        table {
            tbody{
                tr {
                    td{
                        text-align: left;
                    }
                }
            }
        }
    }
}

.pages-contet {
    margin: 70px 0 70px;
    h3 {
        font-size: 28px;
        color: $color02;
        font-weight: bold;
        margin: 0 0 20px;
    }
    h4 {
        font-size: 22px;
        color: $color02;
        font-weight: bold;
        margin: 30px 0 15px;
    }
    h6, h5 {
        font-size: 18px;
        line-height: 23px;
        font-weight: bold;
        color: $textColor1;
        margin: 25px ​0 20px;
    }
    p {
        font-size: 18px;
        line-height: 23px;
        color: $textColor1;
        a {
            color: $color01;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    ul {
        margin: 0 0 15px;
        padding: 0 0 0 20px;
        li {
            /*list-style-type: none;*/
            font-size: 18px;
            line-height: 23px;
            color: $textColor1;
            margin: 0 0 8px;
            a {
                color: $color01;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* 404 - PAGE */
.page-not-found {
    padding: 150px 0px;
    @include media-query($mobile-max) {
        padding: 100px 0px;
    }
    h1 {
        text-align: center;
        font-size: 60px;
        color: $color01;
        font-weight: 900;
    }
    p {
        font-size: 20px;
        text-align: center;
    }
    .Button404 {
        display: table;
        margin: 20px auto;
        background-color: $color01;
        color: $white;
        font-size: 16px;
        line-height: 1;
        text-decoration: none;
        padding: 14px 22px 12px;
        border-radius: 8px;
        text-transform: uppercase;
        transition: 0.3s all;
        &:hover {
            background-color: $color02;
            transition: 0.3s all;
        }
    }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
    display: block;
}
figcaption {
    font-size: $small-font-size;
}

/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}
li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}

/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;
    transition: 0.3s all;
    &:visited {
        color: darken($brand-color, 15%);
    }
    &:hover {
        color: $text-color;
        transition: 0.3s all;
        text-decoration: underline;
    }
}

/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}
code {
    padding: 1px 5px;
}
pre {
    padding: 8px 12px;
    overflow-x: auto;
    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

/**
 * Clearfix
 */
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

/**
 * Icons
 */
.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        path {
            fill: $grey-color;
        }
    }
}
