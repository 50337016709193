@charset "utf-8";


/* FONTS Variables */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
$fontStyle1: 'Jost', sans-serif;
$fontStyle2: 'Helvetica Regular';
$fontStyle2B: 'Helvetica Bold';


@font-face {
	font-family: 'Helvetica Regular';
	src: url('../fonts/Helvetica.ttf')  format('truetype');
}
@font-face {
	font-family: 'Helvetica Bold';
	src: url('../fonts/Helvetica-Bold.ttf')  format('truetype');
}


/* COLOR Variables */
$color01: #ff5851;
$color02: #476D9A; /*3d4461*/
$color03: #c3c3c3;
$color04: #bababa;

$textColor1: #676767;
$textColor2: #333333;
$textColor3: #212121;

$white: #ffffff;
$black: #000000;
$backgroundColor1: #6b6b6b;
$backgroundColor2: #f3f3f3;
$paginationColor: #a7a7a7;

$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$desktop-min:               1600px;
$laptop-max:				1440px;
$tablet-max:				1280px;
$tablet-pot-max:            1199px;
$tablet-pot-min:            1023px;
$tablet-min:				991px;
$mobile-max:				767px;
$mobile-min:				575px;
$mobile-ex-min:				420px;

$on-palm:          			600px;
$on-laptop:        			800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "header",
    "footer",
    "blog",
    "home",
    "syntax-highlighting"
;
