.banner-sections {
	min-height: 450px;
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
    background-position: center;
    @include media-query($tablet-min) {
		min-height: 320px;
    }
	@include media-query($mobile-max) {
		min-height: 280px;
    }
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($backgroundColor1, 0.6);
	}
	.container {
		position: relative;
		h1 {
			font-size: 70px;
			line-height: 90px;
			color: $white;
			font-weight: 600;
			text-align: center;
			text-transform: uppercase;
			font-family: $fontStyle1;
		    @include media-query($tablet-min) {
				font-size: 48px;
				line-height: 52px;
		    }
			@include media-query($mobile-max) {
				line-height: 48px;
				font-size: 42px;
		    }
		}
	}
}
.blog-listing-area {
	padding: 70px 0;
	@include media-query($mobile-max) {
		padding: 50px 0;
    }
	.list-blog-area {
	    /*@include media-query($tablet-min) {*/
	    @include media-query($mobile-max) {
	    	order: 2;
	    }
	}
	.cat-list-area {
	    @include media-query($mobile-max) {
	    	order: 1;
	    	margin: 0 0 50px;
	    }
	}
	.blog-listing-inner-area {
		max-width: 800px;
	    @include media-query($laptop-max) {
			max-width: 100%;
	    }
		.blogs-boxes {
			margin: 0 0 45px;
			.img-blog {
				position: relative;
				margin: 0 0 45px;
				img {
					width: 100%;
					height: 410px;
					object-fit: contain;
					transition: 0.3s all;
					box-shadow: 0 0 9px rgba(0,0,0,0.1);
					background-color: $white;
				    @include media-query($tablet-min) {
						height: 340px;
				    }
	    			@include media-query($mobile-max) {
						height: 285px;
			        }
				}
				.calendar-texts {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 72px;
					padding: 8px 5px;
					background-color: rgba($color02, 0.8);
					font-size: 18px;
				    line-height: 20px;
					color: $white;
					text-align: center;
					text-transform: uppercase;
					margin: 0;
					span {
						font-size: 30px;
						font-family: $fontStyle2B;
						line-height: 1;
					}
				}
				&:hover {
					img {
						opacity: 0.8;
						transition: 0.3s all;
					}
				}
			}
			h6 {
				a {
					font-size: 28px;
					color: $color02;
					font-family: $fontStyle2B;
					text-decoration: none;
					text-transform: uppercase;
				    @include media-query($tablet-min) {
						font-size: 24px;
				    }
					@include media-query($mobile-max) {
						font-size: 22px;
				    }
					&:hover {
						color: $color01;
					}
				}
			}
			p {
				font-size: 18px;
				line-height: 23px;
				color: $textColor1;
				@include media-query($mobile-max) {
					font-size: 16px;
			    }
			}
			.read-button {
				font-size: 18px;
				line-height: 1;
				color: $white;
			    padding: 11px 13px 9px;
				background-color: $color01;
				border-radius: 8px;
			    margin: 10px 0 0;
			    display: inline-block;
				@include media-query($mobile-max) {
					font-size: 16px;
			    }
			    &:hover {
			    	background-color: $color02;
			    	transition: 0.3s all;
			    	text-decoration: none;
			    }
			}
		}
		#pagination-container {
			ul {
				margin: 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				li {
					list-style-type: none;
					padding: 0px;
					margin: 0 5px 5px;
					// width: 41px;
					// height: 41px;
					// line-height: 41px;
					width: 35px;
					height: 35px;
					line-height: 35px;
					border-radius: 5px;
					text-align: center;
					background-color: $white;
					border: 2px solid $paginationColor;
					@include media-query($mobile-max) {
						margin: 0 3px 5px;
					}
					span, a {
						padding: 0px;
					    border: none;
					    margin: 0;
						// font-size: 28px;
					    // line-height: 41px;
					    line-height: 35px;
						font-size: 24px;
					    color: $paginationColor;
					    background-color: transparent;
					    &.prev {
					    	line-height: 32px;
					    	// line-height: 38px;
					    }
					    &.next {
					    	line-height: 32px;
					    	// line-height: 38px;
					    }
						&:focus {
							outline: none;
							box-shadow: none;
						}
					}
					&.active {
						border: 2px solid $color01;
						span {
							color: $color01;
						}
					}
					&.disabled {
						display: none;
					}
					&:hover {
						border: 2px solid $color01;
						a {
							color: $color01;
						}
					}
				}
			}
		}
	}
	.blog-categories-list {
	    background: $white;
	    box-shadow: 0 0 11px rgba($black,0.11);
	    padding: 28px 30px 6px;
	    margin: 0 0 45px;
        @include media-query($mobile-max) {
			margin: 0 0 30px;
        }
	    .inner-area{
	    	h6 {
				font-size: 20px;
				color: $color02;
				font-family: $fontStyle2B;
				margin: 0 0 15px;
	    	}
	    	.categories-list-part {
	    		.archive-group {
				    margin: 28px 0 15px;
				    display: flex;
				    flex-wrap: wrap;
	    			li {
	    				list-style-type: none;
    					margin: 0 0 12px;
    					width: 100%;
						order: 2;
	    				a {
	    					display: flex;
	    					width: 100%;
	    					justify-content: space-between;
	    					text-transform: capitalize;
	    					font-size: 18px;
							color: $textColor1;
							text-align: left;
							text-decoration: none;
					        @include media-query($mobile-max) {
	    						font-size: 16px;
					        }
	    					span {

	    					}
	    					&:hover {
	    						color: $color01;
	    					}
	    				}
						&.nutrition {
							display: none;
						}
						&.recipe {
							display: none;
						}
	    				&.blog {
						    order: 1;
	    				}
	    				&.activemenu {
	    					a {
							    color: $color01;
	    					}
	    				}
	    			}
	    		}
	    	}
	    }
	}
	.blog-recent-post-list {
	    background: $white;
	    box-shadow: 0 0 11px rgba($black,0.11);
	    padding: 28px 30px 6px;
	    .inner-area{
	    	h6 {
				font-size: 20px;
				color: $color02;
				font-family: $fontStyle2B;
				margin: 0 0 15px;
	    	}
	    	.list-blocks {
	    		a {
					font-size: 18px;
					line-height: 1.4;
	    			color: $textColor3;
	    			text-decoration: none;
	    			@include media-query($mobile-max) {
						font-size: 16px;
			        }
	    			&:hover {
	    				color: $color01;
	    			}
	    		}
	    		p {
	    			color: $textColor1;
	    			font-size: 18px;
	    			line-height: 1.4;
	    			@include media-query($mobile-max) {
						font-size: 16px;
			        }
	    			i {
	    				margin: 0 10px 0 0;
	    			}
	    		}
	    	}
	    }
	}
}

/* Single BLOG */
.mid-single-blog-area {
	margin: 70px 0 100px;
	h3 {
		font-size: 28px;
		color: $color02;
		font-weight: bold;
		margin: 25px 0 20px;
		@include media-query($mobile-max) {
			font-size: 24px;
		}
	}
	h4 {
		font-size: 22px;
		color: $color02;
		font-weight: bold;
		margin: 0 0 20px;
		@include media-query($mobile-max) {
			font-size: 20px;
		}
	}
	h6, h5 {
		font-size: 18px;
		line-height: 23px;
		font-weight: bold;
		color: $textColor1;
		margin: 0 0 20px;
		@include media-query($mobile-max) {
			font-size: 16px;
		}
	}
	p {
		font-size: 18px;
		line-height: 23px;
		color: $textColor1;
    	word-break: break-word;
		@include media-query($mobile-max) {
			font-size: 16px;
		}
		a {
			color: $color01;
			&:hover {
				text-decoration: underline;
			}
		}
		span {
			&.heighlight {
				color: $color01;
			}
		}
	}
	ul {
		margin: 0 0 15px;
		padding: 0 0 0 20px;
		li {
			/*list-style-type: none;*/
			font-size: 18px;
			line-height: 23px;
			color: $textColor1;
			margin: 0 0 8px;
			@include media-query($mobile-max) {
				font-size: 16px;
			}
			a {
				color: $color01;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	img {
		display: table;
		margin: 0 auto 15px;
	}
}

// FOR NUTRITION Blogs Pages
.blogs-body {
	&.Nutrition, &.Recipe {
		.blog-listing-area {
			.blog-categories-list {
				.categories-list-part {
					.category-head {
						display: none;
						&.activemenu {
							display: block;
						}
						&.recipe {
							display: block;
						}
						&.nutrition {
							display: block;
						}
					}
				}
			}
		}
	}
}