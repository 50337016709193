/**
 * Site header
 */
.BackScroll {
    position: fixed;
    width: 100%;
}
header {
    /*position: sticky;*/
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    background-color: $white;
    box-shadow: 0 0px 22px 0 rgba($black, 0.1);
    .header-top-contact {
        background-color: #f7f7f7;
        font-family: $fontStyle2;
        padding: 16px 0;
        @include media-query($tablet-max) {
            padding: 14px 0 10px;
        }
        .contact-number-emails {
            display: flex;
            flex-wrap: wrap;
            .inner-box {
                margin: 0 34px 0 0;
                @include media-query($tablet-min) {
                    margin: 0 14px 0 0;
                }
                @include media-query($mobile-max) {
                    margin: 0 10px 0 0;
                }
                a {
                    color: $color02;
                    font-size: 14px;
                    letter-spacing: 0.3px;
                    @include media-query($mobile-max) {
                        font-size: 0px;
                    }
                    i {
                        color: $color01;
                        font-size: 20px;
                        margin: 0 14px 0 0;
                        @include media-query($tablet-min) {
                            margin: 0 8px 0 0;
                        }
                        @include media-query($mobile-max) {
                            font-size: 18px;
                        }
                    }
                    &.phone-m {
                        i {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
        .social-media-links {
            text-align: right;
            a {
                font-size: 18px;
                margin: 0 0 0 18px;
                transition: 0.3s all;
                color: $color02;
                @include media-query($tablet-min) {
                /*@include media-query($mobile-max) {*/
                    font-size: 16px;
                }
                &:hover {
                    transition: 0.3s all;
                    text-decoration: none;
                    color: $color01;
                }
            }
        }
    }
    .mainMenu {
        /*position: relative;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9;
        background-color: $white;*/
        .container {
            .inner-area {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                /*padding: 28px 0;*/
                padding: 20px 0;
                @include media-query($tablet-max) {
                    padding: 22px 0;
                }
                @include media-query($tablet-min) {
                    padding: 16px 0;
                }
                .site-logo {
                    /*width: 330px;*/
                    width: 130px;
                    @include media-query($tablet-max) {
                        /*width: 260px;*/
                        width: 65px;
                    }
                    @include media-query($tablet-min) {
                        width: 65px;
                        /*width: 220px;*/
                    }
                    img {
                        height: 66px;
                        @include media-query($tablet-min) {
                            height: 52px;
                        }
                    }
                }
                .menu-area {
                    display: flex;
                    /*width: calc(100% - 335px);*/
                    width: calc(100% - 135px);
                    vertical-align: middle;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    @include media-query($tablet-max) {
                        width: calc(100% - 70px);
                    }
                    @include media-query($mobile-max) {
                        /*width: calc(100% - 225px);*/
                        width: calc(100% - 70px);
                    }
                    .responsive-toggle {
                        width: 100%;
                        text-align: right;
                        display: none;
                        @include media-query($tablet-min) {
                            display: block;
                        }
                        #hamburger-icon {
                            top: 2px;
                            width: 30px;
                            height: 28px;
                            position: relative;
                            display: inline-block;
                            margin: 12px 0 5px 0;
                            transition: 0.5s all;
                            .line {
                                display: block;
                                background: $color01;
                                width: 30px;
                                height: 3px;
                                position: absolute;
                                left: 0;
                                border-radius: 4px;
                                transition: 0.5s all;
                                &.line-1 {
                                    top: 0;
                                    transition: 0.5s all;
                                }
                                &.line-2 {
                                    top: 40%;
                                    transition: 0.5s all;
                                }
                                &.line-3 {
                                    top: 80%;
                                    transition: 0.5s all;
                                }
                            }
                            &.active {
                                height: 28px;
                                transition: 0.5s all;
                                .line-1 {
                                    /*transform: translateY(25px) translateX(0) rotate(45deg);*/
                                }
                                .line-2 {
                                    /*opacity: 0;*/
                                    transform: translateX(-15px);
                                    transition: 0.5s all;
                                }
                                .line-3 {
                                    transition: 0.5s all;
                                    /*transform: translateY(-25px) translateX(0) rotate(-45deg);*/
                                }
                            }
                        }
                    }
                    ul.nav-ul {
                        padding: 0px;
                        margin: 0px;
                        /*width: 100%;*/
                        text-align: right;
                        transition: 0.5s all;
                        display: inline-block;
                        position: relative;
                        @include media-query($tablet-min) {
                            display: block;
                            position: fixed;
                            /*left: -100%;
                            right: 0;*/
                            right: -100%;
                            bottom: 0;
                            height: 100vh;
                            top: 0;
                            width: 320px;
                        }
                        @include media-query($mobile-ex-min) {
                            width: calc(320px - 75px);
                        }
                        .nav-small-logo {
                            position: absolute;
                            left: 0px;
                            max-width: 100px;
                            width: 100%;
                            margin: 0px;
                            transition: 0.3s all;
                            top: 50%;
                            transform: translateY(-50%);
                            img {
                                width: 100%;
                            }
                            @include media-query($tablet-min) {
                                display: none;
                            }
                        }
                        .nav-ul-inner {
                            @include media-query($tablet-min) {
                                height: 100%;
                                overflow-y: auto;
                                padding: 50px 0 50px;
                            }
                            li {
                                margin: 0 10px;
                                list-style-type: none;
                                display: inline-block;
                                position: relative;
                                @include media-query($laptop-max) {
                                    margin: 0 8px;
                                }
                                @include media-query($tablet-max) {
                                    margin: 0 5px;
                                }
                                @include media-query($tablet-min) {
                                    text-align: left;
                                    width: 100%;
                                    margin: 0px;
                                    border-bottom: 1px solid rgba($black, 0.1);
                                }
                                a {
                                    color: $color02;
                                    transition: 0.5s all;
                                    position: relative;
                                    font-size: 16px;
                                    text-transform: uppercase;
                                    padding: 0;
                                    margin: 0;
                                    font-weight: normal;
                                    @include media-query($desktop-min) {
                                        /*font-size: 22px;*/
                                    }
                                    @include media-query($laptop-max) {
                                        /*font-size: 18px;*/
                                    }
                                    @include media-query($tablet-pot-max) {
                                        font-size: 14px;
                                    }
                                    @include media-query($tablet-min) {
                                        color: $color02;
                                        padding: 16px 10px 14px;
                                        line-height: 1;
                                        text-align: left;
                                        width: 100%;
                                        display: block;
                                    }
                                    &:after {
                                        display: none;
                                    }
                                    &:hover {
                                        color: $color01;
                                        text-decoration: none;
                                    }
                                }
                                &.dropdown {
                                    padding-right: 15px;
                                    margin-right: 0;
                                    cursor: pointer;
                                    position: relative;
                                    @include media-query($tablet-min) {
                                        padding-right: 0px;
                                    }
                                    span.Open-Submenu {
                                        position: absolute;
                                        top: 0;
                                        right: 0px;
                                        pointer-events: none;
                                        @include media-query($tablet-max) {
                                            top: -2px;
                                        }
                                        @include media-query($tablet-min) {
                                            pointer-events: auto;
                                            z-index: 1;
                                            font-size: 22px;
                                            top: 5px;
                                            width: 35px;
                                            text-align: center;
                                        }
                                    }
                                    .dropdown-menu {
                                        border-radius: 0px;
                                        margin: 5px 0 0;
                                        border: none;
                                        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
                                        @include media-query($tablet-min) {
                                            position: relative;
                                            width: 95%;
                                            box-shadow: none;
                                            border: 1px solid rgba(0,0,0,.15);
                                            margin: 0 auto 12px;
                                            float: none;
                                        }
                                        li {
                                            width: 100%;
                                            margin: 0px;
                                            padding: 0;
                                            a {
                                                padding: 5px 15px;
                                                &:active {
                                                    background-color: transparent;
                                                }
                                            }
                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                    &:hover {
                                        @media (min-width: 992px) {
                                            .dropdown-menu {
                                                display: block !important;
                                            }
                                        }
                                    }
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        width: 100%;
                                        right: 0;
                                        bottom: 0;
                                        top: 20px;
                                        padding: 15px 0 0;
                                        @include media-query($tablet-min) {
                                            display: none;
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        /*font-weight: 600;*/
                                        text-shadow: 1px 1px 0px $color01;
                                        color: $color01;
                                        @include media-query($tablet-min) {
                                            display: block;
                                        }
                                    }
                                }
                                /*&.appointment-btn {
                                    margin-right: 0px;
                                    margin-left: 30px;
                                    @include media-query($desktop-min) {
                                        margin-left: 10px;
                                    }
                                    @include media-query($tablet-min) {
                                        margin-left: 0px;
                                    }
                                    a {
                                        margin: 0;
                                        padding: 16px 14px 14px;
                                        border-radius: 8px;
                                        color: $white;
                                        background-color: $color01;
                                        @include media-query($tablet-min) {
                                            border-radius: 0px;
                                        }
                                        &:hover {
                                            background-color: $color02;
                                            transition: 0.3s all;
                                            text-shadow: none;
                                        }
                                    }
                                }*/
                            }
                            .close-toggle {
                                display: none;
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 0;
                                border: none;
                                width: 35px;
                                max-width: 35px;
                                height: 38px;
                                font-size: 40px;
                                line-height: 0.5;
                                z-index: 6;
                                color: $color01;
                                background-color: $white;
                                text-transform: uppercase;
                                @include media-query($tablet-min) {
                                    display: block;
                                    display: flex;
                                    align-items: center;
                                }
                                &:focus {
                                    outline: none;
                                }
                                &:hover {
                                    background-color: $white;
                                }
                            }
                        }
                        &.openDown {
                            display: block;
                            position: fixed;
                            /*left: 0;
                            right: 0;*/
                            right: 0;
                            bottom: 0;
                            height: 100vh;
                            background: $white;
                            top: 0;
                            padding: 0 60px;
                            width: 100%;
                            /*width: 320px;*/
                            @include media-query($mobile-max) {
                                /*width: calc(320px - 75px);*/
                                padding: 0 20px;
                            }
                        }
                    }
                    .appointment-btn {
                        margin-right: 0px;
                        margin-left: 30px;
                        @include media-query($desktop-min) {
                            margin-left: 10px;
                        }
                        @include media-query($tablet-min) {
                            margin-left: 0px;
                        }
                        a {
                            margin: 0;
                            padding: 16px 14px 14px;
                            border-radius: 8px;
                            text-decoration: none;
                            color: $white;
                            background-color: $color01;
                            @include media-query($tablet-pot-max) {
                                font-size: 14px;
                                padding: 14px 12px 12px;
                            }
                            @include media-query($tablet-min) {
                                border-radius: 0px;
                                display: block;
                                text-align: center;
                                padding: 14px 10px 12px;
                            }
                            &:hover {
                                background-color: $color02;
                                transition: 0.3s all;
                                text-shadow: none;
                            }
                            /*@include media-query($desktop-min) {
                                font-size: 22px;
                            }
                            @include media-query($laptop-max) {
                                font-size: 18px;
                            }
                            @include media-query($tablet-max) {
                                font-size: 16px;
                                margin: 0;
                            }*/
                        }
                    }
                }
                .site-title {
                    font-size: $spacing-unit;
                    color: $color01;
                    font-weight: 300;
                    line-height: 56px;
                    letter-spacing: -1px;
                    margin-bottom: 0;
                    float: left;
                }
            }
        }
    }
}
